<template>
  <section class="main-list" data-testid="element_000065">
    <template v-if="category.category">
      <div data-testid="element_000066">
        <component :is="tag" :class="styles?.category" class="category stl-title-26" data-testid="element_000067">{{ category.category }}</component>
        <ui-link
          :link="category.link"
          class="change-category link stl-link-blue stl-bold600 mini"
          role="button"
          @click="$emit('open')"
        >
          {{ category.link.title }}
        </ui-link>
      </div>

      <slot />
    </template>
    <div class="content-block-list content-block-list_mini mt2-12" data-testid="element_000068">
      <template v-for="card in category.list" :key="`${card.id || card.slug}`">
        <video-card-skeleton v-if="'_skeleton' in card" :strait="false" />
        <video-card v-else :video="card" :playing-video-id="touchedVideoId" @touchstart="setTouchedVideoId(card)"/>
      </template>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LinkComponent from '@/components/ui/link/link.vue'
import VideoCard from '@/components/ui/card/video-card/video-card.vue'
import VideoCardSkeleton from '~/components/ui/card/video-card/video-card-skeleton.vue'

export default defineComponent({
  name: "ComponentMainList",
  props: {
    category: {
      type: Object as any,
      required: true,
    },
    tag: {
      type: String,
      required: true,
    },
    styles: {
      type: Object as any,
      default: () => ({ category: '' }),
    }
  },
  components: {
    VideoCardSkeleton,
    LinkComponent,
    VideoCard,
  },
})
</script>

<script setup lang="ts">
const touchedVideoId = ref('')
const setTouchedVideoId = (card) => {
  touchedVideoId.value = card.id
}
</script>

<style lang="scss">
.main-list {
  line-height: 0;

  .category {
    margin: 0;
  }

  .change-category {
    line-height: 22rem;
    cursor: pointer;
  }
}
</style>
